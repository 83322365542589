import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { FOCUS_THROTTLE_INTERVAL } from '@/config/appSettings';

import { IResponseError, getTokenFromCookie } from '@/lib/apiBase';
import { fetchUserCredits, TNamespaceCredit, IUserCreditsResponse } from '@/lib/userCreditsApi';

const useNamespaceCredits = (initialData?: IUserCreditsResponse) => {
  const [namespaceCredits, setNamespaceCredits] = useState<TNamespaceCredit | undefined>(initialData?.namespace_credit);
  const [error, setError] = useState<IResponseError | undefined>();
  const token = getTokenFromCookie();

  const {
    data,
    error: requestError,
    isValidating,
  } = useSWR(`namespace-credits-${token}`, () => fetchUserCredits(token), {
    keepPreviousData: true,
    focusThrottleInterval: FOCUS_THROTTLE_INTERVAL,
    fallbackData: initialData,
  });

  useEffect(() => {
    if (data) {
      setNamespaceCredits(data.namespace_credit);
      setError(requestError as IResponseError);
    }
  }, [data, requestError]);

  const loading: boolean = isValidating && !namespaceCredits;

  return { loading, namespaceCredits, error };
};

export default useNamespaceCredits;
